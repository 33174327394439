import React, { useState } from "react";
import { Link } from "react-router-dom";
import whiteMinimisGlass from "../../assests/images/white-minimis-glasses.png";
import minimisMobileView from "../../assests/images/minimis-mobile-view.png";
import darkGlass from "../../assests/images/dark-glass-1.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import logo from "../../assests/images/MINIMIS.svg";
import { ToastContainer } from "react-toastify";
import GetInTouchModal from "./getInTouchModal";

const DesktopView = ({
  isHovered,
  setIsHovered,
  isLauncherHover,
  setIsLauncherHover,
  onGlassClick,
  onLauncherClick,
  onFacebookClick,
  onLinkdinClick,
  onInstagramClick,
  handleEmailChange,
  setEmail,
  saveEmailInDataBase,
  email,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  // Functions to handle modal open/close
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleGlassReadMoreClick = () => {
    setIsHovered(true); // Trigger hover-like effect on click for Minimis Glass
  };

  const handleLauncherReadMoreClick = () => {
    setIsLauncherHover(true); // Trigger hover-like effect on click for Minimis Launcher
  };

  return (
    <div>
      <ToastContainer />
      <GetInTouchModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <div className="landingPage">
        <div className="row">
          <div className="textContainer">
            <div className="flexDiv">
              <div className="textDiv">
                <img
                  className="minimisLogo"
                  src={logo}
                  style={{ fill: "black", stroke: "black" }}
                  alt=""
                />
                <p>
                  We build technology <br /> to minimize distractions and
                  maximize life.
                </p>
              </div>

              <div className="buttonDiv">
                <p>
                  Join us in making real life better through beautiful
                  technology.
                </p>
                <div className="mt-3 d-flex">
                  <div>
                    <input
                      value={email}
                      onChange={handleEmailChange}
                      type="text"
                      placeholder="Your email..."
                    />
                  </div>
                  {/* <button onClick={saveEmailInDataBase}>Submit</button> */}
                  <button
                    onClick={saveEmailInDataBase}
                    style={{ marginLeft: "10px" }}
                  >
                    Get in touch
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="imagesPart">
            <div className="BoxDiv">
              {isHovered ? (
                <div className="boxes-div">
                  <div
                    className="glassHoverDiv"
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    <div>
                      <h2>MINIMIS FLOW AR</h2>
                      <div className="paragraph-heading">
                        <p>
                          Standalone AR HUD sunglasses + phone + running watch +
                          bike computer
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="hoverText">
                        <p className="p1">
                          See metrics, navigate, make calls & listen to music
                          within sunglasses, without looking down or carrying
                          anything else.
                        </p>
                        <p className="p2">
                          Covered by 40+ publications, join hundreds of athletes
                          who’ve chosen to enhance their performance and move
                          safer.
                        </p>
                        {/* <button onClick={onGlassClick}>Preorder now</button> */}
                      </div>
                      <div className="glassImg1">
                        <center>
                          <img src={darkGlass} alt="" />
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="boxes">
                  <div className="main-glass-img px-5">
                    <p>MINIMIS FLOW AR</p>
                    <div>
                      <img
                        className="glassImg"
                        src={whiteMinimisGlass}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        bottom: "32px",
                        position: "absolute",
                      }}
                    >
                      <p>
                        <Link to="/" onClick={handleGlassReadMoreClick}>
                          Read more
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <hr />
              {isLauncherHover ? (
                <div className="boxes-div bottom-area">
                  <div
                    className="launcherHoverDiv"
                    onMouseLeave={() => setIsLauncherHover(false)}
                  >
                    <div>
                      <h2>MINIMIS PHONE</h2>

                      <div className="paragraph-heading">
                        <p>Minimal distractions. Maximal life.</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="hoverText">
                        <p className="p3">
                          Minimis Launcher brings your phone back to basics with
                          built-in mindfulness checks, so you can be take back
                          control of your phone usage.
                        </p>
                        <p className="p4">
                          Join thousands of others and start saving time now.
                        </p>
                        <button onClick={onLauncherClick}>
                          Get lifetime access
                        </button>
                      </div>
                      <div className="launcherImg1">
                        <center>
                          <div className="phone-mockup-class"></div>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="boxes">
                  <div
                    // onMouseEnter={() => setIsLauncherHover(true)}
                    className="launcherImg px-5"
                  >
                    <p>MINIMIS PHONE</p>
                    <div>
                      <img
                        className="mobileImg"
                        src={minimisMobileView}
                        alt=""
                      />
                    </div>
                    <div style={{ bottom: "32px", position: "absolute" }}>
                      <p>
                        <Link to="/" onClick={handleLauncherReadMoreClick}>
                          Read more
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="iconsDiv">
            <div id="icons">
              <div>
                <FaInstagram onClick={onInstagramClick} />
              </div>
              <div>
                <FaLinkedinIn onClick={onLinkdinClick} />
              </div>
              <div>
                <FaFacebookF onClick={onFacebookClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopView;
