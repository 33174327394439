import React, { useState } from "react";
import launcher1 from "../../assests/images/mobile-text-img-2.png";
import minimisMobileView from "../../assests/images/minimis-mobile-view.png";
import whiteMinimisGlass from "../../assests/images/white-minimis-glasses.png";
import darkGlass from "../../assests/images/dark-glass-1.png";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import logo from "../../assests/images/MINIMIS-logo-mobile-tab.svg";
import { ToastContainer } from "react-toastify";

//Mobile View Component
const MobileView = ({
  onGlassClick,
  onLauncherClick,
  onFacebookClick,
  onInstagramClick,
  onLinkdinClick,
  handleEmailChange,
  setEmail,
  saveEmailInDataBase,
  email,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showLauncherMore, setShowLauncherMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const toggleShowLauncherMore = () => {
    setShowLauncherMore(!showLauncherMore);
  };

  return (
    <div>
      <ToastContainer />
      <div className="mobileView overflow-x-hidden">
        <div className="headingDiv overflow-x-hidden d-flex justify-content-between ">
          <div>
            <img
              src={logo}
              style={{
                fill: "black",
                stroke: "black",
                height: "20px",
                width: "126px",
              }}
              alt=""
            />
          </div>
          {/* <div><img src={menu} style={{ height: '24px', width: '20px' }} /></div> */}
        </div>
        <div className="flexDiv">
          <div className="textDiv">
            <p className="">
              We build technology to minimize distractions and maximize life.
            </p>
          </div>
        </div>

        <div
          className="contentClass"
          style={{ marginTop: "25px", height: "auto" }}
        >
          {showMore ? (
            <div className={`glassMobileDesign`}>
              <div className="additionalText">
                <h6>MINIMIS FLOW AR</h6>
                <p>
                  Standalone AR HUD sunglasses + phone + running watch + bike
                  computer
                </p>
              </div>
              <center>
                <img className="mt-2" src={darkGlass} alt="" />
              </center>
              <div className="additionalButton">
                <div className="contentDiv">
                  <p>
                    See metrics, navigate, make calls & listen to music within
                    sunglasses, without looking down or carrying anything else.
                  </p>
                  <p className="my-3">
                    Covered by 40+ publications, join hundreds of athletes
                    who’ve chosen to enhance their performance and move safer.
                  </p>
                  {/* <p className="">
                    Covered by 40+ publications, $169,000 in pre-orders,
                    shipping January 2025.
                  </p> */}
                  <div
                    className=""
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "24px",
                    }}
                  >
                    <div>
                      <input
                        type="submit"
                        className=""
                        onClick={onGlassClick}
                        value="Preorder now"
                      />
                    </div>
                    <div>
                      <button onClick={toggleShowMore}>
                        See less
                        <IoIosArrowUp className="ms-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="box1Mobile">
              <p>MINIMIS FLOW AR</p>
              <div className="">
                <img className="" src={whiteMinimisGlass} alt="" />
                <button id="slideButton" onClick={toggleShowMore}>
                  See more
                  <IoIosArrowDown className="ms-1" />
                </button>
              </div>
            </div>
          )}

          <hr style={{ width: "100%" }} />

          {showLauncherMore ? (
            <div className={`LauncherMobileDesign`}>
              <div className="additionalText">
                <h6>MINIMIS PHONE</h6>
                <p>Minimal distractions. Maximal life.</p>
              </div>
              <div className="imgDiv">
                <img className="" src={launcher1} alt="" />
              </div>
              <div className="additionalButton">
                <p>
                  Minimis Launcher brings your phone back to basics with
                  built-in mindfulness checks, so you can be take back control
                  of your phone usage.
                </p>
                <p className="my-3">
                  Join thousands of others and start saving time now.
                </p>
                {/* <p className="">
                  Join 36,000+ others and get early access below.
                </p> */}
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "24px",
                  }}
                >
                  <div>
                    <input
                      type="submit"
                      onClick={onLauncherClick}
                      className=""
                      value="Get lifetime access"
                    />
                  </div>
                  <div>
                    <button onClick={toggleShowLauncherMore}>
                      See less <IoIosArrowUp />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="box2Mobile ">
              <p>MINIMIS PHONE</p>
              <div className=" ">
                <img className="" src={minimisMobileView} alt="" />
                <button onClick={toggleShowLauncherMore}>
                  See more <IoIosArrowDown />
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="inputBtnDiv ">
          <p className="">
            Join us in making real life better through beautiful technology.
          </p>
          <div className="BTnDiv">
            <div className="inputDiv">
              <input
                value={email}
                onChange={handleEmailChange}
                type="text"
                placeholder="Your email..."
              />
            </div>
            <div className="getInDiv">
              <button onClick={saveEmailInDataBase}>Get in touch</button>
            </div>
          </div>
        </div>

        <div className="MobileiconsDiv">
          <div>
            <FaInstagram onClick={onInstagramClick} />
          </div>
          <div>
            <FaLinkedinIn onClick={onLinkdinClick} />
          </div>
          <div>
            <FaFacebookF onClick={onFacebookClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
