import { useState } from "react";
import { Modal } from "react-bootstrap";
import mail from "../../assests/images/mail-logo.png";

const ModalBox = ({
  showModal,
  closeModal,
  saveEmailInDataBase,
  handleSubmit,
  interest,
  handleDropdownChange,
  message,
  handleMessageChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const preorderLink = "https://app.minimis.life/"; // Replace with actual link
  const downloadLink =
    "https://play.google.com/store/apps/details?id=life.minimis.launcher&hl=en&pli=1"; // Replace with actual download link

  const handleFormSubmit = async () => {
    if (message.trim() !== "") {
      setIsLoading(true); // Show preloader
      await handleSubmit(); // Call the original submit function
      setTimeout(() => {
        setIsLoading(false);
        setShowThankYou(true); // Show thank-you modal
      }, 2000); // Simulating a delay for preloader
    } else {
      handleSubmit(); // Submit normally if no message
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Body>
        {showThankYou ? (
          <div className="thank-you-content">
            <h2 className="thank-you-heading">Thank You!</h2>
            <div className="thank-you-touch" style={{ marginBottom: "8px" }}>
              We will be in touch soon.
            </div>
            <div className="thank-you-img">
              <img src={mail} alt="Mail logo" />
            </div>
            <button onClick={closeModal}>Close</button>
          </div>
        ) : saveEmailInDataBase ? (
          <div>
            <h2 className="dropdown-heading">Get in touch</h2>
            <p className="dropdown-list">I'm interested in:</p>
            <select
              className="modal-dropdown"
              value={interest}
              onChange={handleDropdownChange}
            >
              <option className="dropdown-option" value="Investing">
                Investing
              </option>
              <option className="dropdown-option" value="Minimis Flow AR">
                Minimis Flow AR
              </option>
              <option
                className="dropdown-option"
                value="Minimis Phone iOS Access"
              >
                Minimis Phone iOS Access
              </option>
              <option
                className="dropdown-option"
                value="Minimis Phone iOS Question"
              >
                Minimis Phone iOS Question
              </option>
              <option
                className="dropdown-option"
                value="Minimis Phone Android Access"
              >
                Minimis Phone Android Access
              </option>
              <option
                className="dropdown-option"
                value="Minimis Phone Android Question"
              >
                Minimis Phone Android Question
              </option>
            </select>

            {/* Hide textarea for "Minimis Phone iOS Access" & "Minimis Phone Android Access" */}
            {![
              "Minimis Phone iOS Access",
              "Minimis Phone Android Access",
            ].includes(interest) && (
              <div className="dropdown-optional-text">
                <textarea
                  placeholder="Your message..."
                  value={message}
                  onChange={handleMessageChange}
                ></textarea>
              </div>
            )}

            {/* Show preloader if loading */}
            {isLoading ? (
              <div className="preloader">Submitting...</div>
            ) : interest === "Minimis Phone iOS Access" ? (
              <a href={preorderLink} target="_blank" rel="noopener noreferrer">
                <button className="mt-4">Preorder Now</button>
              </a>
            ) : interest === "Minimis Phone Android Access" ? (
              <a href={downloadLink} target="_blank" rel="noopener noreferrer">
                <button className="mt-4">Download Now</button>
              </a>
            ) : (
              <button onClick={handleFormSubmit}>Submit</button>
            )}
          </div>
        ) : (
          <div className="thank-you-content">
            <h2 className="thank-you-heading">Thank You!</h2>
            <div className="thank-you-touch" style={{ marginBottom: "8px" }}>
              We will be in touch soon.
            </div>
            <div className="thank-you-img">
              <img src={mail} alt="Mail logo" />
            </div>
            <button onClick={closeModal}>Close</button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalBox;
